import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class VariaveisService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	/**
	 * @param {number[]} idGrupos
	 * @param {string[]} tipos
	 * @param {string} tag
	 * @param {string} descricao
	 */
	async listVariables (idGrupos, tipos, tag, descricao) {
		const response = await this.axios.get("variables", {
			params: {
				id_grupos: (idGrupos || []).join(","),
				tipos: (tipos || []).join(","),
				tag,
				descricao
			}
		});
		return response.data;
	}

	async addVariables (variavel) {
		const response = await this.axios.post("variables", variavel);
		return response.data;
	}

	async updateVariables (id, variavelAtualizada) {
		const response = await this.axios.put(`variables/${id}`, variavelAtualizada);
		return response.data;
	}

	async deleteVariables (id) {
		const response = await this.axios.delete(`variables/${id}`);
		return response.data;
	}
}
