<template>
	<form>
		<div>
			<div class="form-row">
				<div class="form-group col-md-4">
					<label>Tag *</label>
					<b-form-input type="text" v-model="tagInput">
					</b-form-input>
				</div>

				<div class="form-group col-md-4">
					<label>Descrição: </label>
					<b-form-input type="text" v-model="descricaoInput"></b-form-input>
				</div>

				<div class="form-group col-md-4">
					<label for="i_nome">Grupo *</label>
					<vue-multi-select
						class="multi-100"
						v-model="grupoSelecionado"
						search
						historyButton
						:selectOptions="gruposOpcoes"
						data-cy="Grupos"
						:btnLabel="
							() =>
								grupoSelecionado[0]
									? grupoSelecionado[0].nome
									: 'Selecione'
						"
					/>
				</div>

				<div class="form-group col-md-4 pt-2">
					<label for="i_tipo">Tipo *</label>
						<vue-multi-select
							class="multi-100"
							v-model="tipoVariavel"
							historyButton
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="tiposOpcoes"
							:btnLabel="() => tipoVariavel[0] ? tipoVariavel[0] : 'Selecione'"
							:disabled="editing"
						/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica'">
					<label class="mt-2">Unidade:</label>
					<b-form-input
						class="multi-100"
						v-model="unidadeInput"
					/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica'">
					<label class="mt-2">Taxa de amostragem (ms) *</label>
						<b-form-input
							class="multi-100"
							type="number"
							v-model="taxaAmostragem"
							min="0"
							step="1"
						/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica'">
					<label>Variação mínima *</label>
						<b-form-input
							class="multi-100"
							type="number"
							min="0"
							:formatter="formataParaDecimal"
							v-model="varMin"
						/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica'">
					<label>Valor mínimo:</label>
					<b-form-input
						type="number"
						v-model="valorMin"
					/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica'">
					<label>Valor máximo:</label>
					<b-form-input
						type="number"
						v-model="valorMax"
					/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica'">
					<label>Níveis de alarmes a serem utilizados:</label>
					<vue-multi-select
						class="multi-100"
						v-model="alarmesSelecionados"
						:selectOptions="alarmeOpcoes"
						:options="options"
						data-cy="Níveis"
						:btnLabel="
							() => alarmesSelecionados[0] ? alarmesSelecionados.map(l => l.substr(7)).join(', ') : 'Nenhum'
						"
					/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica' && alarmesSelecionados.includes('Alarme HH')">
					<label>Alarme HH:</label>
					<b-form-input type="number" step="0.01" v-model="hh" :state="validationHH"/>
					<b-form-invalid-feedback :state="validationHH">
						O Alarme HH tem que ser maior que os H, L e LL
					</b-form-invalid-feedback>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica' && alarmesSelecionados.includes('Alarme H')">
					<label>Alarme H:</label>
					<b-form-input type="number" step="0.01" v-model="h" :state="validationH"/>
					<b-form-invalid-feedback :state="validationH">
						O Alarme H tem que ser maior que os L e LL e menor que o HH
					</b-form-invalid-feedback>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica' && alarmesSelecionados.includes('Alarme L')">
					<label>Alarme L:</label>
					<b-form-input type="number" step="0.01" v-model="l" :state="validationL"/>
					<b-form-invalid-feedback :state="validationL">
						O Alarme L tem que ser maior que o LL e menor que os HH e H
					</b-form-invalid-feedback>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Analógica' && alarmesSelecionados.includes('Alarme LL')">
					<label>Alarme LL:</label>
					<b-form-input type="number" step="0.01" v-model="ll" :state="validationLL"/>
					<b-form-invalid-feedback :state="validationLL">
						O Alarme LL tem que ser menor que os HH, H e L
					</b-form-invalid-feedback>
				</div>

				<div class="form-group col-md-4 d-flex"
					v-if="tipoVariavel[0] === 'Analógica' && (alarmesSelecionados.includes('Alarme HH') || alarmesSelecionados.includes('Alarme LL'))"
				>
					<div class="d-flex align-items-top" style="margin-top: 36px;">
						<b-form-checkbox id="flag-intertravamento" switch size="lg" v-model="intertravamento" :disabled="!hh && !ll"/>
						<label for="flag-intertravamento" class="mb-0">
							Habilitar Intertravamento
							<InfoIcon size="15" id="popover-target-1"/>
						</label>
						<b-popover target="popover-target-1" triggers="hover" placement="right" custom-class="w-75">
							<template #title>Intertravamento</template>
							<p>
								Quando habilitado intertravamento, os alarmes HH e LL irão impedir o funcionamento da máquina.
							</p>
						</b-popover>
					</div>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Estado ON:</label>
					<b-form-input class="multi-100" v-model="estadoOn"/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Estado OFF:</label>
					<b-form-input class="multi-100" v-model="estadoOff"/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Taxa de amostragem (ms) *</label>
					<b-form-input
						class="multi-100"
						type="number"
						min="0"
						step="1"
						v-model="taxaAmostragem"
					/>
				</div>

				<div class="form-group col-md-4" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Variação mínima *</label>
					<b-form-input
						class="multi-100"
						type="number"
						v-model="varMin"
						:formatter="formataParaDecimal"
						min="0"
					/>
				</div>
			</div>

			<p class="small text-right mt-3 mb-3 mr-3">
				Campos marcados com * são obrigatórios.
			</p>

			<div v-if="editing" class="d-flex justify-content-between">
				<button
					class="btn btn-secondary align-self-center mr-2"
					type="button"
					data-cy="Cancelar"
					@click.stop="$emit('cancelar')"
				>
					Cancelar
				</button>
				<button
				v-if="possuiPermissao('GER_D_VARIAVEIS')"
					class="btn btn-danger align-self-center mr-auto"
					type="button"
					data-cy="Remover"
					@click="remover"
					:disabled="loading || saving || !editing"
				>
					Remover
				</button>
				<button
					v-if="possuiPermissao('GER_U_VARIAVEIS')"
					class="btn btn-success align-self-center"
					type="button"
					data-cy="Salvar"
					:disabled="isFormInvalid"
					@click="salvarEdicao"
				>
					Salvar
				</button>
			</div>

			<div v-if="!editing" class="d-flex justify-content-end">
				<button
					class="btn btn-success align-self-center lg"
					type="button"
					data-cy="Adicionar"
					:disabled="isFormInvalid"
					@click="adicionar"
				>
					Adicionar
				</button>
			</div>
		</div>
	</form>
</template>

<script>
	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import { VariaveisService } from "../services/variaveis";
	import { GruposService } from "../services/grupos";

	import { possuiPermissao } from "../helpers/permissions";

	function options (timeout) {
		return {
			timeout: timeout || 2000,
			showProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true
		};
	}

	export default {
		name: "AddEditVariavel",
		components: {
			VueMultiSelect
		},

		props: {
			itemId: Number
		},

		watch: {
			itemId: {
				handler: "onChange",
				immediate: true
			},
			intertravamento (newValue) {
				if (this.openingEdit)
					this.openingEdit = false;
				else if (newValue)
					this.confirmarIntertravamento();
			},
			alarmesSelecionados (newValeu) {
				const newWarning = [];
				for (const alarme of newValeu) {
					if (!this.warning.includes(alarme))
						newWarning.push(alarme);
				}
				this.warning = newWarning;
			}
		},

		data () {
			return {
				tagInput: "",

				gruposOpcoes: [],
				grupoSelecionado: [],

				tipoVariavel: [],
				tiposOpcoes: ["Analógica", "Digital"],

				nomeAlarmes: {
					hh: "Alarme HH",
					h: "Alarme H",
					l: "Alarme L",
					ll: "Alarme LL"
				},
				alarmeOpcoes: [],
				alarmesSelecionados: [],
				hh: null,
				h: null,
				l: null,
				ll: null,
				intertravamento: false,
				warning: [],

				unidadeInput: "",
				variavelInput: "",
				descricaoInput: "",

				estadoOn: "",
				estadoOff: "",

				valorMin: null,
				valorMax: null,

				taxaAmostragem: null,
				varMin: "0.000",

				variavel: [],

				errMsg: "",

				loading: false,
				editing: false,
				openingEdit: false,
				saving: false,
				deleting: false,
				loadingPromise: Promise.resolve(),

				gruposService: new GruposService(),
				variaveisService: new VariaveisService(),
				possuiPermissao,

				options: {
					multi: true
				}
			};
		},

		async mounted () {
			this.loadingPromise = this.getOptions();
			this.alarmeOpcoes.push(this.nomeAlarmes.hh, this.nomeAlarmes.h, this.nomeAlarmes.l, this.nomeAlarmes.ll);
		},

		computed: {
			validationHH () {
				const selecionados = this.getAlarmesSelecionados(this.nomeAlarmes.hh);
				if (this.hh > Math.max(...selecionados)) {
					this.removerErro(this.nomeAlarmes.hh);
					return null;
				}

				this.adicionarErro(this.nomeAlarmes.hh);
				return false;
			},

			validationH () {
				const isSelectedHH = this.alarmesSelecionados.includes(this.nomeAlarmes.hh);
				const selecionados = this.getAlarmesSelecionados(this.nomeAlarmes.h);
				if (this.h > Math.max(...selecionados) && (!isSelectedHH || Number(this.h) < Number(this.hh))) {
					this.removerErro(this.nomeAlarmes.h);
					return null;
				}
				this.adicionarErro(this.nomeAlarmes.h);
				return false;
			},

			validationL () {
				const isSelectedLL = this.alarmesSelecionados.includes(this.nomeAlarmes.ll);
				const selecionados = this.getAlarmesSelecionados(this.nomeAlarmes.l);
				if (this.l < Math.min(...selecionados) && (!isSelectedLL || Number(this.l) > Number(this.ll))) {
					this.removerErro(this.nomeAlarmes.l);
					return null;
				}
				this.adicionarErro(this.nomeAlarmes.l);
				return false;
			},

			validationLL () {
				const selecionados = this.getAlarmesSelecionados(this.nomeAlarmes.ll);
				if (this.ll < Math.min(...selecionados))	{
					this.removerErro(this.nomeAlarmes.ll);
					return null;
				}
				this.adicionarErro(this.nomeAlarmes.ll);
				return false;
			},

			isFormInvalid () {
				return this.loading ||
					this.saving ||
					this.warning.length ||
					!this.varMin.length ||
					!this.grupoSelecionado[0] ||
					!this.tagInput ||
					!this.tipoVariavel[0] ||
					this.taxaAmostragem === null;
			}
		},

		methods: {
			adicionarErro (msg) {
				const index = this.warning.indexOf(msg);
				if (index > -1)
					this.warning[index] = msg;
				else
					this.warning.push(msg);
			},

			removerErro	(msg) {
				const index = this.warning.indexOf(msg);
				if (index > -1)
					this.warning.splice(index, 1);
			},

			getAlarmesSelecionados (alarme) {
				const isSelectedHH = this.alarmesSelecionados.includes(this.nomeAlarmes.hh);
				const isSelectedH = this.alarmesSelecionados.includes(this.nomeAlarmes.h);
				const isSelectedL = this.alarmesSelecionados.includes(this.nomeAlarmes.l);
				const isSelectedLL = this.alarmesSelecionados.includes(this.nomeAlarmes.ll);
				const selecionados = [];

				if (isSelectedH && alarme != this.nomeAlarmes.h)
					selecionados.push(this.h);
				if (isSelectedHH && (alarme == this.nomeAlarmes.l || alarme == this.nomeAlarmes.ll))
					selecionados.push(this.hh);
				if (isSelectedL && alarme != this.nomeAlarmes.l)
					selecionados.push(this.l);
				if (isSelectedLL && alarme != this.nomeAlarmes.ll && alarme != this.nomeAlarmes.l)
					selecionados.push(this.ll);

				return selecionados;
			},

			onChange () {
				if (this.itemId > 0)
					this.editar();
				else
					this.novoItem();
			},

			formataParaDecimal (value) {
				if (value)
					return parseFloat(value).toFixed(3);
			},

			novoItem () {
				this.editing = false;

				this.grupoSelecionado.splice(0);

				this.tagInput = "";
				this.descricaoInput = "";

				this.taxaAmostragem = 0;
				this.varMin = "0.000";
				this.tipoVariavel = [];

				this.valorMin = 0;
				this.valorMax = 0;
				this.unidadeInput = "";

				this.estadoOn = "";
				this.estadoOff = "";
			},

			async getOptions () {
				this.gruposOpcoes = ((await this.gruposService.listGroups()) || []).map(
					g => ({ name: g.nome, ...g })
				);
			},

			async adicionar () {
				try {
					this.saving = true;

					this.limparAlarmesNaoSelecionado();

					const payload = {
						tag: this.tagInput,
						descricao: this.descricaoInput,
						id_grupo: this.grupoSelecionado.map(g => g.id),
						tipo:
							this.tipoVariavel[0] === "Analógica"
								? (this.tipoVariavel[0] = "ANALOGICA")
								: "DIGITAL",
						valor_minimo: this.valorMin,
						valor_maximo: this.valorMax,
						unidade: this.unidadeInput,
						estado_on: this.estadoOn,
						estado_off: this.estadoOff,
						taxa_amostragem: this.taxaAmostragem,
						variacao_minima: this.varMin,
						hh: this.hh,
						h: this.h,
						ll: this.ll,
						l: this.l,
						intertravamento: this.intertravamento
					};

					await this.variaveisService.addVariables(payload);

					this.$emit("salvar-adicao", this.itemId);
					this.$snotify.success("Variável adicionada", "Sucesso!");
				} catch (err) {
					this.saving = false;

					console.log(err);
					this.$snotify.async(
						"Aguarde...",
						() => new Promise((resolve, reject) => {
							setTimeout(() => reject({
								title: "Erro ao salvar",
								config: options()
							})
							);
						})
					);
				}
			},

			async editar () {
				this.editing = true;
				await this.loadingPromise;

				const variaveis = await this.variaveisService.listVariables();
				const variavel = variaveis.find(v => v.id === this.itemId);

				this.grupoSelecionado[0] = this.gruposOpcoes.find(g => g.id === variavel.id_grupo);

				this.tagInput = variavel.tag;
				this.descricaoInput = variavel.descricao;

				this.taxaAmostragem = variavel.taxa_amostragem;
				this.varMin = variavel.variacao_minima.toFixed(3);
				this.tipoVariavel = variavel.tipo === "ANALOGICA" ? ["Analógica"] : ["Digital"];

				this.valorMin = variavel.analogica ? variavel.analogica.valor_minimo : "";
				this.valorMax = variavel.analogica ? variavel.analogica.valor_maximo : "";
				this.unidadeInput = variavel.analogica ? variavel.analogica.unidade : "";

				this.estadoOn = variavel.digital ? variavel.digital.estado_on : "";
				this.estadoOff = variavel.digital ? variavel.digital.estado_off : "";

				this.intertravamento = variavel.analogica.intertravamento;
				if (this.intertravamento) this.openingEdit = true;

				if (variavel.analogica.hh || variavel.analogica.hh == 0) {
					this.hh = variavel.analogica.hh;
					this.alarmesSelecionados.push("Alarme HH");
				}

				if (variavel.analogica.h || variavel.analogica.h == 0) {
					this.h = variavel.analogica.h;
					this.alarmesSelecionados.push("Alarme H");
				}

				if (variavel.analogica.l || variavel.analogica.l == 0) {
					this.l = variavel.analogica.l;
					this.alarmesSelecionados.push("Alarme L");
				}

				if (variavel.analogica.ll || variavel.analogica.ll == 0) {
					this.ll = variavel.analogica.ll;
					this.alarmesSelecionados.push("Alarme LL");
				}
			},

			limparAlarmesNaoSelecionado () {
				let alarmeHH = true;

				if (!this.alarmesSelecionados.includes("Alarme HH") || this.hh === "") {
					this.hh = null;
					alarmeHH = false;
					if (this.intertravamento) {
						if (!this.ll || !this.alarmesSelecionados.includes("Alarme LL") || this.ll == "")
							this.intertravamento = false;
					}
				}

				if (!this.alarmesSelecionados.includes("Alarme H") || this.h === "") this.h = null;
				if (!this.alarmesSelecionados.includes("Alarme L") || this.l == "") this.l = null;

				if (!this.alarmesSelecionados.includes("Alarme LL") || this.ll === "") {
					this.ll = null;
					if (!alarmeHH) this.intertravamento = false;
				}
			},

			async salvarEdicao () {
				const id = this.itemId;

				try {
					this.saving = true;

					this.limparAlarmesNaoSelecionado();

					const payload = {
						id_grupo: this.grupoSelecionado[0].id,
						tag: this.tagInput,
						descricao: this.descricaoInput,

						tipo:
							this.tipoVariavel[0] === "Analógica"
								? (this.tipoVariavel[0] = "ANALOGICA")
								: "DIGITAL",
						taxa_amostragem: this.taxaAmostragem,
						variacao_minima: this.varMin,

						valor_minimo: this.valorMin,
						valor_maximo: this.valorMax,
						unidade: this.unidadeInput,

						estado_on: this.estadoOn,
						estado_off: this.estadoOff,

						hh: this.hh,
						h: this.h,
						l: this.l,
						ll: this.ll,
						intertravamento: this.intertravamento
					};

					await this.variaveisService.updateVariables(+id, payload);

					this.$emit("salvar-edicao", this.itemId);
					this.$snotify.success("Variável salva", "Sucesso!");
				} catch (err) {
					this.saving = false;

					console.log(err);
					this.$snotify.async(
						"Aguarde...",
						() => new Promise((resolve, reject) => {
							setTimeout(() => reject({
								title: "Erro ao salvar",
								config: options()
							})
							);
						})
					);
				}
			},

			async cancelar () {
				this.saving = false;
			},

			async apagar () {
				const id = this.itemId;
				this.variaveis = await this.variaveisService.listVariables();

				const idx = this.variaveis.findIndex(c => c.id === +id);
				if (idx >= 0) {
					this.variaveis.splice(idx, 1);
				}
			},

			async remover () {
				const id = this.itemId;
				const variavel = await this.variaveisService.listVariables();
				const tagVar = variavel.find(v => v.id === +id && v.tag);

				this.deleting = true;

				const doDelete = () => this.variaveisService.deleteVariables(id)
					.then(res => res.data.error ? Promise.reject(res.data.error) : res.data)
					.catch(reason => {
						if (
							reason.response &&
							reason.response.data &&
							reason.response.data.error
						) {
							this.$swal.showValidationMessage(
								"Falha ao excluir: " +
									reason.response.data.error
							);

							this.deleting = false;
						}
					});

				this.$swal
					.fire({
						title: "Você tem certeza?",
						text: `A remoção de ${tagVar.tag} é irreversível`,
						type: "warning",
						showCancelButton: true,
						reverseButtons: true,
						focusCancel: true,
						confirmButtonColor: "#dc3545",
						confirmButtonText: "Remover",
						cancelButtonText: "Cancelar",
						allowOutsideClick: () => !this.$swal.isLoading(),
						showLoaderOnConfirm: true,
						preConfirm: doDelete
					})
					.then(res => {
						if (res.value) {
							this.$emit("remover-variavel", this.itemId);

							this.$snotify.success("Variável removida", "Sucesso!");
							this.apagar(+id);
						}
					});
			},

			confirmarIntertravamento () {
				this.$swal
					.fire({
						title: "Deseja continuar?",
						text: "Quando habilitado intertravamento, os alarmes HH e LL irão impedir o funcionamento da máquina.",
						type: "warning",
						showCancelButton: true,
						focusCancel: true,
						confirmButtonColor: "#dc3545",
						confirmButtonText: "Sim",
						cancelButtonText: "Não",
						reverseButtons: true
					})
					.then(res => {
						if (res.value)
							this.intertravamento = true;
						else
							this.intertravamento = false;
					});
			}
		}
	};
</script>

<style scoped>
	label[for="flag-intertravamento"] {
		line-height: 32px;
	}
</style>
